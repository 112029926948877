import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import WorkPreview from '../components/work-preview';
import AboutContact from '../components/about-contact';
import { Seo } from '../components/seo';

const Work = ({ data }) => {
  return (
    <Layout navBarLink="navBarLinkWorkPage" navBarLinkWork="navBarLinkWork">
      <Seo title="Work" />
      <WorkPreview />
      <AboutContact />
    </Layout>
  );
};

export default Work;

export const pageQuery = graphql`
  query WorkQuery {
    work: allContentfulWork(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        type
        slug
        visuals {
          gatsbyImageData
        }
      }
    }
  }
`;
