import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const WorkPreview = () => {
  const data = useStaticQuery(graphql`
    query Works {
      works: allContentfulWork(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          type
          alignment
          marginTop
          marginLeft
          marginRight
          slug
          visuals {
            gatsbyImageData(height: 400)
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className='list-none w-full pt-0 lg:pt-20'>
        {data.works.nodes.map((work) => {
          const alignment =
            work.alignment === 'left' ? 'mr-0 lg:mr-auto' : 'ml-0 lg:ml-auto';

          return (
            <li key={work.id} className='w-full flex pb-12 lg:pb-3 mt-0'>
              <div className={`${alignment}`}>
                <div
                  style={{
                    marginTop: `${work.marginTop}px`,
                    marginLeft: `${work.marginLeft}px`,
                    marginRight: `${work.marginRight}px`,
                  }}
                  className='hidden lg:block'
                >
                  <Link to={work.slug}>
                    <GatsbyImage
                      image={work.visuals[0].gatsbyImageData}
                      className='opacity-100 hover:opacity-90'
                    />
                  </Link>
                  <div className='block lg:flex items-center pt-3 text-sm lg:text-base'>
                    <p className=''>{work.title}.</p>
                    <p className='ml-0 lg:ml-3'> {work.type}</p>
                  </div>
                </div>
                <div className='block lg:hidden'>
                  <Link to={work.slug}>
                    <GatsbyImage
                      image={work.visuals[0].gatsbyImageData}
                      className=''
                    />
                  </Link>
                  <div className='block lg:flex items-center pt-3 text-sm lg:text-base'>
                    <p className=''>{work.title}.</p>
                    <p className='ml-0 lg:ml-3'> {work.type}</p>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </div>
    </section>
  );
};

export default WorkPreview;
