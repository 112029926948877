import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const AboutContact = () => {
  const data = useStaticQuery(graphql`
    query about {
      contentfulAbout(title: { eq: "about" }) {
        id
        title
        content {
          raw
        }
      }
    }
  `);

  const richTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className='text-xs lg:text-sm pb-3 lg:pb-3 whitespace-pre-wrap'>
          {children}
        </div>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <div className='text-xs lg:text-sm pt-3 lg:pt-6 pb-3'>{children}</div>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className='list-none leading-none'>{children}</ul>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target='_blank'
            rel='noopener noreferrer'
            className='font-semibold text-pwxBlue hover:underline'
          >
            {children}
          </a>
        );
      },
    },
  };

  return (
    <section id='about' className='w-full lg:w-1/3 px-2 lg:px-0 py-12'>
      <div className=''>
        <div>
          {data.contentfulAbout.content &&
            renderRichText(data.contentfulAbout.content, richTextOptions)}
        </div>
      </div>
    </section>
  );
};

export default AboutContact;
